import moment from 'moment'

export const globalMixin = {
  methods: {
    formatDateMixin: (value = undefined, format = 'DD.MM.YYYY', valueFormat = undefined) => {
      moment.locale('ru')
      return moment(value, valueFormat).format(format)
    },

    getValidityPeriodMixin(startDate, endDate) {
      const start = moment(startDate, 'DD.MM.YYYY')
      return moment(endDate, 'DD.MM.YYYY').diff(start, 'day')
    },

    prepareFormDataObjectMixin(data, myObjData, key) {
      Object.entries(data).forEach(([key2, value]) => {
        const str = key === '' ? key2 : `${key}[${key2}]`

        if (Array.isArray(value)) {
          myObjData = { ...myObjData, ...this.prepareFormDataArrayMixin(value, myObjData, str) }
        } else if (Object.prototype.toString.call(value) === '[object Object]') {
          myObjData = { ...myObjData, ...this.prepareFormDataObjectMixin(value, myObjData, str) }
        } else {
          myObjData[str] = value
        }
      })

      return myObjData
    },

    prepareFormDataArrayMixin(data, myObjData, key) {
      data.forEach((value, idx) => {
        const str = key + `[${idx}]`

        if (Array.isArray(value)) {
          myObjData = { ...myObjData, ...this.prepareFormDataArrayMixin(value, myObjData, str) }
        } else if (Object.prototype.toString.call(value) === '[object Object]') {
          myObjData = { ...myObjData, ...this.prepareFormDataObjectMixin(value, myObjData, str) }
        } else {
          myObjData[str] = value
        }
      })

      return myObjData
    },

    getFormDataMixin(formObject) {
      const formDataObject = this.prepareFormDataObjectMixin(formObject, {}, '')

      return Object.entries(formDataObject).reduce((fd, [key, value]) => {
        if (value !== null && value !== undefined) {
          fd.append(key, value)
        }
        return fd
      }, new FormData())
    },

    onUpCyrillicMixin([store, field, upper = false], value) {
      if (value) {
        this.form[field] = upper ? value[0].toUpperCase() + value.slice(1) : value

        const errors = this.$store.state[store].errors

        if (/^\w+$/.test(value)) {
          this.$store.commit(`${store}/setErrors`, {
            ...errors,
            [field]: 'Вы вводите латинские буквы!'
          })
        } else {
          this.$store.commit(`${store}/setErrors`, { ...errors, [field]: undefined })
        }
      }
    },

    valueToUpperCaseMixin: value => (value && value[0].toUpperCase() + value.slice(1)).trim(),

    getFormattedCurrencyMixin(amount, currency = true) {
      const amountClean = isNaN(amount) ? 0 : amount

      return new Intl.NumberFormat('ru-RU', {
        ...(currency && {
          style: 'currency',
          currency: 'RUB'
        }),
        minimumFractionDigits: 0
      }).format(amountClean)
    },

    formatFileSizeMixin(value) {
      return parseInt((value / 1e6) * 1000) / 1000
    },

    getTranslitMixin(value) {
      // prettier-ignore
      const converter = {
        'а': 'a',
        'б': 'b',
        'в': 'v',
        'г': 'g',
        'д': 'd',
        'е': 'e',
        'ё': 'e',
        'ж': 'zh',
        'з': 'z',
        'и': 'i',
        'й': 'y',
        'к': 'k',
        'л': 'l',
        'м': 'm',
        'н': 'n',
        'о': 'o',
        'п': 'p',
        'р': 'r',
        'с': 's',
        'т': 't',
        'у': 'u',
        'ф': 'f',
        'х': 'h',
        'ц': 'c',
        'ч': 'ch',
        'ш': 'sh',
        'щ': 'sch',
        'ь': '',
        'ы': 'y',
        'ъ': '',
        'э': 'e',
        'ю': 'yu',
        'я': 'ya'
      }
      value = value.toLowerCase()

      let answer = ''
      for (let i = 0; i < value.length; ++i) {
        if (converter[value[i]] === undefined) {
          answer += value[i]
        } else {
          answer += converter[value[i]]
        }
      }

      return answer
        .replace(/[^-0-9a-z]/g, '-')
        .replace(/[-]+/g, '-')
        .replace(/^\\-|-$/g, '')
    },

    generateInnMixin() {
      const inn = Array.from(10, () => Math.floor(Math.random() * 10))

      let sum1 = inn.reduce((acc, val, idx) => acc + val * [7, 2, 4, 10, 3, 5, 9, 4, 6, 8][idx], 0)
      inn.push((sum1 % 11) % 10)

      let sum2 = inn.reduce(
        (acc, val, idx) => acc + val * [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8][idx],
        0
      )
      inn.push((sum2 % 11) % 10)

      return inn.join('')
    }
  }
}
